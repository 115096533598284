export const environment = {
  apiUrl: 'https://api.demo.cliv.info',
  ENV: 'prod',
  sentry: {"enable":true,"enableReplayIntegration":false,"enableBrowserTracingIntegration":true},
  dataName: 'general',
  clientName: 'demo',
  enableReset: false,
  production: true,
  uiClienId: 'a2cb7ab5-5b7a-4d43-8dcb-c364369697a8',
  redirectUrl: 'https://demo.cliv.info',
  navBar: {
    leftImage: 'assets/svg/logo-dark.svg',
    leftImageHeight: 30,
    menuSetting: '[{"name":"reports","default":true,"page-name":"ReportSectionf71766f04794c71c8443","report-id":"03c75a3f-61d6-49ea-b340-005928ac6ed9"},{"name":"dashboards","default":false,"page-name":"ReportSection791bcdeec022f2c08cd5","report-id":"03c75a3f-61d6-49ea-b340-005928ac6ed9"},{"name":"inspection","default":false}]',
    roleFilter: '{}',
    showAdmin: false,
    asDropdown: false,
  },
  sideBar: '{"inspection-plan-title":"AQL Inspection Plan","show-skip-inspections":false,"inspections-icon":"t-shirt.svg","show-po-selection":false,"show-packaging":true,"show-downloads":true}',
  import: '{"select-group":false,"title":"UPLOAD SUPPLIER BOOKING DETAILS","select-centre":true,"select-inspection-level":false,"select-inspection-type":false}',
  uploadSizeChart: '{"add-title":"ADD STYLE","need-upload":true,"title":"UPLOAD SIZE CHART","delete-title":"DELETE STYLE CODE","product-code-title":"Style Code"}',
  clientInspection: '{"po-number":"PO Number","consol-filter":"Consol. Centre","table":[{"name":"Insp. Date","case":"name","key":"inspection_date"},{"name":"Loc.","type":"number","case":"normal","key":"UDC_id"},{"name":"Insp.","type":"number","case":"normal","key":"inspection_number"},{"name":"Supplier Name","case":"name","key":"supplier_name"},{"name":"Season","case":"normal","key":"season_code"},{"name":"PO Numbers","case":"normal","key":"client_pos"},{"name":"Style","case":"normal","key":"style_code"},{"name":"Description","case":"name","key":"style_name"},{"name":"Colour","case":"name","key":"colour"},{"name":"Result","case":"result","key":"result"},{"name":"Full Report","type":"pdf","case":"file"},{"name":"Summary Report","type":"pdf","case":"summary_file"}],"date-range-minus":1,"department-list":"","division-filter":"","colour-filter":"Colour","result-filter":"Result"}',
  selectInspection: '{"order-number":true,"select-centre":true,"show-open-order-filter":false,"date-range-plus":0,"table":[{"name":"Arrived","case":"name","key":"arrived_consol_date"},{"name":"Size Chart","type":"number","case":"normal","key":"size_chart_YN"},{"name":"Insp.","type":"number","case":"normal","key":"inspection_number"},{"name":"Style","case":"normal","key":"style_code"},{"name":"TOT. QTY.","type":"number","case":"normal","key":"order_quantity"},{"name":"Comp.","case":"check","key":"select_for_compliance"},{"name":"AQL","case":"check","key":"select_for_AQL"},{"name":"Inspection Date","case":"select_date","key":"inspection_date"},{"name":"Supplier Name","case":"name","key":"supplier_name"},{"name":"PO Numbers","case":"normal","key":"client_pos","empty":true},{"name":"Action","case":"insp-action"}],"date-title":"Arrived Date","date-range-minus":1}',
  aqlPlan: '{"date-range-plus":0,"table":[{"name":"AQL","show":true,"case":"select_unselect","key":"qcdata_select_for_AQL"},{"name":"Insp. Date","show":true,"case":"name","key":"inspection_date"},{"name":"Loc.","show":true,"type":"number","case":"normal","key":"UDC_id"},{"name":"Insp.","show":true,"type":"number","case":"normal","key":"inspection_number"},{"name":"Supplier Name","show":true,"case":"name","key":"supplier_name"},{"name":"Season","show":true,"case":"normal","key":"season_code"},{"name":"PO Numbers","show":true,"case":"normal","key":"client_pos"},{"name":"STYLE","show":true,"case":"normal","key":"style_code"},{"name":"Description","show":true,"case":"name","key":"style_name"},{"name":"Colour","show":true,"case":"name","key":"colour"},{"name":"PO QTY.","show":true,"type":"number","case":"normal","key":"order_quantity"},{"name":"Level","show":true,"type":"number","case":"level","key":"sample_level"},{"name":"INSP. Qty.","show":true,"type":"number","case":"normal","key":"inspection_quantity"},{"name":"SUB.","show":false,"type":"number","case":"normal","key":"inspection_number"},{"name":"AQL Maj<","show":true,"type":"number","case":"normal","key":"allowed_defects_maj"},{"name":"AQL Min<","show":true,"type":"number","case":"normal","key":"allowed_defects_min"},{"name":"Tot. Defects","show":true,"type":"number","case":"normal","key":"count_defects"},{"name":"Current Status","show":true,"case":"normal","key":"status_EN"},{"name":"Result","show":true,"case":"result","key":"result"},{"name":"Inspector Name","show":true,"case":"normal","key":"Inspector"},{"name":"Customer","show":false,"case":"normal","key":"customer_name"}],"select-centre":true,"show-color":true,"title":"AQL Inspection Plan","show-po-number":true,"date-range-minus":1,"show-petek-number":false,"style-code":"Style Code","show-inspection-type":false,"status-filter":"Status"}',
  aqlReport: '{"show-close-btn":false,"show-max-allowed":true,"info-fields":[{"field":"style_code","title":"Style Code"},{"field":"style_name","title":"Style Name"},{"field":"colour","title":"Colour"},{"field":"supplier_name","title":"Supplier"},{"field":"PO_Nos","title":"PO Number"},{"field":"Order_Size","title":"Order Size"},{"field":"inspection_number","title":"Submission"},{"field":"sample_level","title":"Inspection Level"},{"field":"inspection_quantity","title":"Sample Qty."}],"show-result":true,"show-summary-inspection-report":true,"title":"AQL Inspection Report","major":"Major","critical":"Critical","show-centre":true,"show-inspection-date":false,"show-customer-name":false,"minor":"Minor","show-inspection-type":false,"show-inspection-id":true}',
  mainPage: {"redirect-urls":[{"name":"brand","url":"https://brand.cliv.info/"}]},
};